<template>
  <div>
    <v-layout wrap justify-center class="mainfont">
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#283e51"
        spinner="spinner"
      />
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs11 lg3 pt-7>
            <span style="font-size: 25px; font-weight: bolder">
              ADMIN SECTION CLERK
            </span>
          </v-flex>
          <v-flex xs11 lg8 pr-3 pt-7 text-right>
            <v-layout wrap justify-end>
              <v-flex xs3 lg1>
                <v-btn
                  v-if="!clerkExists"
                  block
                  @click="openDialog(false)"
                  class="bgcustom mainfont text-center"
                >
                  <span class="mainfont" style="color: white"> Add </span>
                </v-btn>
                <v-btn
                  v-else
                  block
                  @click="openDialog(true)"
                  class="bgcustom mainfont text-center"
                >
                  <span class="mainfont" style="color: white"> Edit </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog1" max-width="500px">
      <v-card>
        <v-layout wrap pa-4>
          <v-flex xs12 text-center>
            <span class="mainfont">{{ editMode ? "Edit Clerk" : "Add Clerk" }}</span>
          </v-flex>
        </v-layout>
        <v-card-text>
          <v-text-field
            class="mainfont"
            v-model="username"
            label="Username"
            outlined
            :rules="usernameRules"
          ></v-text-field>
          <v-text-field
            class="mainfont"
            v-model="officeName"
            label="Office Name"
            outlined
            :rules="officenameRules"
          ></v-text-field>
          <v-text-field
            class="mainfont inputPrice"
            v-model="mobNumber"
            label="Mobile Number"
            type="number"
            outlined
            :rules="mobNumberRules"
          ></v-text-field>
          <v-text-field
            class="mainfont"
            v-model="emailId"
            label="Email ID"
            outlined
            :rules="emailRules"
          ></v-text-field>
          <v-text-field
            v-if="!editMode"
            class="mainfont"
            v-model="password"
            label="Password"
            outlined
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-text-field
            v-if="!editMode"
            class="mainfont"
            v-model="confirmPassword"
            label="Confirm Password"
            outlined
            :type="showConfirmPassword ? 'text' : 'password'"
            :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showConfirmPassword = !showConfirmPassword"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-layout wrap justify-center pb-4>
            <v-flex xs3 pr-2>
              <v-btn
                color="red"
                block
                @click="dialog1 = false"
                class="mainfont text-center"
              >
                <span style="color: white"> Cancel </span>
              </v-btn>
            </v-flex>
            <v-flex xs3 pl-2>
              <v-btn
                block
                @click="addOrUpdateClerk"
                class="mainfont bgcustom text-center"
              >
                <span style="color: white">{{ editMode ? "Update" : "Add" }}</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      officeName: "",
      mobNumber: "",
      password: "",
      confirmPassword: "",
      emailId: "",
      dialog1: false,
      appLoading: false,
      msg: null,
      showSnackBar: false,
      showPassword: false,
      showConfirmPassword: false,
      editMode: false,
      clerkId: null,
      clerkExists: false,
      clerkData: null,
    };
  },
  mounted() {
    this.getClerk();
  },
  computed: {
    usernameRules() {
      return [(v) => !!v || "Username is required"];
    },
    officenameRules() {
      return [(v) => !!v || "Office Name is required"];
    },
    mobNumberRules() {
      return [
        (v) => !!v || "Mobile number is required",
        (v) => /^[0-9]{10}$/.test(v) || "Mobile number must be 10 digits",
      ];
    },
    emailRules() {
      return [(v) => !!v || "Email is required"];
    },
  },
  methods: {
    openDialog(edit) {
      this.editMode = edit;
      if (edit && this.clerkData) {
        this.username = this.clerkData.username;
        this.officeName = this.clerkData.officeName;
        this.mobNumber = this.clerkData.mobNumber;
        this.emailId = this.clerkData.emailId;
        this.clerkId = this.clerkData._id;
      } else {
        this.resetForm();
      }
      this.dialog1 = true;
    },
    resetForm() {
      this.username = "";
      this.officeName = "";
      this.mobNumber = "";
      this.password = "";
      this.confirmPassword = "";
      this.emailId = "";
      this.clerkId = null;
    },
    addOrUpdateClerk() {
      let validationErrors = [];

      if (!this.username) validationErrors.push("Please enter a username.");
      if (!this.officeName) validationErrors.push("Please enter an office name.");
      if (!this.mobNumber) validationErrors.push("Please enter a mobile number.");
      if (!this.emailId) validationErrors.push("Please enter an email ID.");

      if (!this.editMode) {
        if (!this.password) validationErrors.push("Please enter a password.");
        if (!this.confirmPassword)
          validationErrors.push("Please enter the confirm password.");
        if (this.password !== this.confirmPassword)
          validationErrors.push("Password and Confirm Password do not match.");
      }

      if (validationErrors.length > 0) {
        this.msg = validationErrors.join(" ");
        this.showSnackBar = true;
        return;
      }

      const clerkData = {
        username: this.username,
        officeName: this.officeName,
        mobNumber: this.mobNumber,
        emailId: this.emailId,
      };

      if (!this.editMode) {
        clerkData.password = this.password;
      } else {
        clerkData.status = "Active";
      }

      axios({
        method: this.editMode ? "PUT" : "POST",
        url: this.editMode ? `/edit/edituser/${this.clerkId}` : "/admin/add/ministerial",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: clerkData,
      })
        .then((response) => {
          this.dialog1 = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.getClerk();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getClerk() {
      axios({
        method: "GET",
        url: "/list/ministerial",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status && response.data.data.length > 0) {
            this.clerkExists = true;
            this.clerkData = response.data.data[0];
          } else {
            this.clerkExists = false;
            this.clerkData = null;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.no-spinners {
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
